import { acquireAccessToken, parseJwt, CenteredDiv } from '@wavetronix/common-components'
import { useEffect, useState, useMemo } from 'react'
import { useMsal } from '@azure/msal-react'
import { env } from '../../index.js'
import '../../../node_modules/react-grid-layout/css/styles.css'
import '../../../node_modules/react-resizable/css/styles.css'
import { WidthProvider, Responsive } from 'react-grid-layout'
import ProductTypeCard from '../controls/ProductTypeCard'
import { useQuery } from '@tanstack/react-query'
import CategoriesApi from '../../api/CategoriesApi'
import { Tab, Tabs } from '@mui/material'
import ProductFamilyCard from '../controls/ProductFamilyCard'
import { Box } from '@mui/system'
import { CircularProgress } from '@mui/material'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

const ReactGridLayout = WidthProvider(Responsive)

const classes = {
  tab: {
    width: '180px'
  }
}

export const isRightColumn = id => {
  if (
    id === 'Accessory Product Overview' ||
    id === 'Accessory Technical Specifications' ||
    id === 'Accessory Bid Specifications'
  ) {
    return true
  } else {
    return false
  }
}

export const generateLayout = (data, col, variableHeight) => {
  return data.map((d, index) => {
    return {
      x: isRightColumn(d.id) ? 1 : index % col,
      y: 0,
      w: 1,
      h: variableHeight && d.docs ? d.docs.length + 1 : 1,
      i: d.id
    }
  })
}

export default function DocumentsTypeDisplayPage({ state, setState, cookies, setCookie }) {
  const { instance, accounts } = useMsal()
  const [downloadToken, setDownloadToken] = useState('')
  const [categoryOptions, setCategoryOptions] = useState([])
  let location = useLocation()
  let querystring = location.search.length > 0 && location.search[0] === '?' ? location.search.substring(1) : ''
  let activeFamily = qs.parse(querystring).family

  const { data, error, isLoading } = useQuery({
    queryKey: ['categories'],
    queryFn: async () =>
      await CategoriesApi.getCategories(instance, accounts).then(res => {
        return res
      })
  })

  useEffect(() => {
    //set state to first product family type
    if (activeFamily === 'undefined') {
      setState(s => ({ ...s, current: cookies['fromFamily'] }))
    } else if (activeFamily && activeFamily !== '') {
      setState(s => ({ ...s, current: activeFamily }))
    } else if (data) {
      let options = [
        ...new Set(
          data
            .filter(c => {
              if (c.categoryType === 'Product Family' && c.isArchived === false) {
                return true
              } else {
                return false
              }
            })
            .sort((a, b) => (a.order > b.order ? 1 : -1))
        )
      ]

      if (options.length > 0) {
        setState(s => ({ ...s, current: options[0].name }))
      }
    }
  }, [data, setState, activeFamily, cookies])

  useEffect(() => {
    if (data && data.length !== 0 && state) {
      let options = [
        ...new Set(
          data
            .filter(c => {
              if (c.categoryType === 'Product' && c.isArchived === false && c.family === state.current) {
                return true
              } else {
                return false
              }
            })
            .sort((a, b) => (a.order > b.order ? 1 : -1))
        )
      ]
      setCategoryOptions(options)
    }
  }, [data, state])

  const categoryFamilies = useMemo(() => {
    let res = []
    if (data) {
      res = data.filter(c => c.categoryType === 'Product Family' && c.isArchived === false)
    }
    return res.sort((a, b) => (a.order > b.order ? 1 : -1))
  }, [data])

  const productUrlMap = useMemo(() => {
    return categoryFamilies.reduce(
      ([map, index], obj) => {
        if (!map[obj.name]) {
          map[obj.name] = index
        }
        return [map, index++]
      },
      [{}, 0]
    )
  }, [categoryFamilies])

  useEffect(() => {
    async function resetDownloadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setDownloadToken(token)
    }
    if (!downloadToken || parseJwt(downloadToken).exp < Date.now() / 1000) {
      resetDownloadToken()
    }
  }, [instance, accounts, downloadToken, setCookie])

  const onChange = family => {
    setState({ ...state, current: family })
  }

  if (isLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }

  if (error) {
    return <CenteredDiv>Error retrieving product options...</CenteredDiv>
  }

  return (
    <>
      <Box
        sx={{
          borderBottom: 2,
          borderColor: 'black',
          width: '80%',
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <Tabs
          value={state && productUrlMap && productUrlMap[state.current] ? productUrlMap[state.current] : 0}
          TabIndicatorProps={{ style: { background: 'transparent' } }}
          variant='scrollable'
          scrollButtons='auto'
        >
          {categoryFamilies.map(p => {
            return (
              <Tab
                id={`${p.name}FamilyTab`}
                key={p.name}
                sx={classes.tab}
                label={
                  <ProductFamilyCard product={p} currentState={state.current === '' ? categoryFamilies[0].name : state.current} />
                }
                onClick={() => onChange(p.name)}
              />
            )
          })}
        </Tabs>
      </Box>
      <CenteredDiv>
        <div style={{ width: '60%' }}>
          <ReactGridLayout
            className='layout'
            isDraggable={false}
            isResizable={false}
            rowHeight={100}
            margin={[20, 30]}
            breakpoints={{ lg: 1200, md: 996, sm: 660, xs: 480, xxs: 0 }}
            cols={{ lg: 2, md: 2, sm: 2, xs: 1, xxs: 1 }}
            layouts={{
              lg: generateLayout(categoryOptions, 2, false),
              xxs: generateLayout(categoryOptions, 1, false)
            }}
          >
            {categoryOptions.map(category => {
              return (
                <div key={category.id}>
                  <ProductTypeCard product={category} family={state} setCookie={setCookie} />
                </div>
              )
            })}
          </ReactGridLayout>
        </div>
      </CenteredDiv>
    </>
  )
}
