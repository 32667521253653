import PageNavbar from '../PageNavbar'
import ProductDocumentBundler from './ProductDocumentBundler'
import { URL_MAP } from '../MainPage'

export default function SubmittalsManagementPage({ productDocMap, ...props }) {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <PageNavbar
          value={props.showFullList ? URL_MAP[props.tabState.current] : 2}
          onChangeCallback={id => props.changeTabState(s => ({ ...s, current: id }))}
        />
        <ProductDocumentBundler productDocMap={productDocMap} />
      </div>
    </>
  )
}
