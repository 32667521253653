import * as React from 'react'
import { IconButton } from '@mui/material'
import CloudDownloadIcon from '../icons/CloudDownloadIcon'

export function DownloadButtonWtx({ url, token, variant, style, fileName, ...props }) {
  return (
    <form action={url} method='post' id='downloadButton'>
      <IconButton type='submit' name='token' value={token} disableRipple sx={style.buttonStyle} {...props} size='large'>
        <CloudDownloadIcon />
        <div style={{ ...style.downloadLink, marginLeft: 10, marginTop: 5 }}>{fileName}</div>
      </IconButton>
    </form>
  )
}
