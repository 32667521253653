import { CircularProgress, TextField, Box } from '@mui/material'
import { useMsal } from '@azure/msal-react'
import {
  CenteredDiv,
  CustomSelect,
  ErrorMessage,
  useWtxLocalization,
  ListSelector,
  CustomAccordion,
  HasAccess,
  CustomCheckbox
} from '@wavetronix/common-components'
import { useEffect, useState, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { containsSubstring } from '../../utils/stringUtils'
import CategoriesApi from '../../api/CategoriesApi'
import { env } from '../../index.js'
export const DEFAULT_DOCUMENTS_FILTER = {
  name: '',
  accessLevel: 'All Levels',
  productTypes: [],
  documentTypes: [],
  languageTypes: [],
  description: '',
  showHidden: false,
  showOverrides: false,
  showIsArchived: false
}

export const filterDocuments = (filter, documents, displayedProducts) => {
  return documents
    ? documents.filter(d => {
        return (
          (filter.showIsArchived === false ? d.products.filter(p => displayedProducts.includes(p)).length > 0 : true) &&
          (d.accessLevel === filter.accessLevel || filter.accessLevel === 'All Levels') &&
          (containsSubstring(d.fileName, filter.name) || filter.name === '') &&
          (containsSubstring(d.description, filter.description) || filter.description === '') &&
          (d.products.filter(p => filter.productTypes.map(fp => fp.id).includes(p)).length > 0 ||
            filter.productTypes.length === 0) &&
          (filter.documentTypes.map(df => df.id).includes(d.documentType) || filter.documentTypes.length === 0) &&
          (d.language.filter(p => filter.languageTypes.map(fp => fp.id).includes(p)).length > 0 ||
            filter.languageTypes.length === 0)
        )
      })
    : []
}

export default function DocumentsFilterDrawer({ filter, setFilter, options, justDocType = false }) {
  const { instance, accounts } = useMsal()
  const [documentTypes, setDocumentTypes] = useState([])
  const [languageTypes, setLanguageTypes] = useState([])
  let localizedStrings = useWtxLocalization()

  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError
  } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      let categories = await CategoriesApi.getCategories(instance, accounts)
      return categories
    }
  })

  useEffect(() => {
    if (categories) {
      let docTypes = categories
        .filter(c => c.categoryType === 'Document Type')
        .sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
      let langTypes = categories.filter(c => c.categoryType === 'Language')

      setDocumentTypes(docTypes)
      setLanguageTypes(langTypes)

      //set filter default, by first finding if there is a language that matches (should always include doc with matching base prefix) if not then don't set filter
      let browserLang = langTypes.filter(
        l =>
          l.name === window.navigator.language ||
          (l.name.length >= 2 && window.navigator.language.length === 2
            ? l.name.slice(0, 2) === window.navigator.language
            : false) ||
          (l.name.length === 2 && window.navigator.language.length >= 2
            ? window.navigator.language.slice(0, 2) === l.name
            : false)
      )

      if (browserLang.length > 0) {
        setFilter(f => ({ ...f, languageTypes: browserLang }))
      }
    }
  }, [categories, setFilter])

  const productTypes = useMemo(() => {
    let res = []
    if (categories) {
      res =
        filter.showIsArchived === false
          ? categories.filter(c => c.categoryType === 'Product' && c.isArchived === false)
          : categories.filter(c => c.categoryType === 'Product')
    }
    return res.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
  }, [filter, categories])

  if (categoriesLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }
  if (categoriesError) {
    return (
      <CenteredDiv>
        <ErrorMessage error={categoriesError} />
      </CenteredDiv>
    )
  }

  if (justDocType === true) {
    return (
      <div>
        <CustomAccordion defaultExpanded={true} disableGutters title={localizedStrings.documentType}>
          <ListSelector
            selectedOptions={filter.documentTypes}
            options={
              documentTypes
                ? documentTypes.map(pType => ({
                    id: pType.id,
                    value:
                      pType.localization && pType.localization[window.navigator.language]
                        ? pType.localization[window.navigator.language]
                        : pType.name
                  }))
                : []
            }
            onChange={products => setFilter(f => ({ ...f, documentTypes: products }))}
            style={{ width: '100%' }}
          />
        </CustomAccordion>
      </div>
    )
  } else {
    return (
      <>
        <TextField
          id='filterNameTextField'
          style={{ width: '100%' }}
          label={localizedStrings.name}
          size='small'
          value={filter.name}
          variant='outlined'
          onChange={e => setFilter(f => ({ ...f, name: e.target.value }))}
        />
        <CustomSelect
          id='filterArccessLevelSelect'
          style={{ marginTop: '15px', width: '100%' }}
          label={localizedStrings.accessLevel}
          value={filter.accessLevel}
          onChange={e => setFilter(f => ({ ...f, accessLevel: e.target.value }))}
          options={[...options]}
        />
        <CustomAccordion defaultExpanded={false} disableGutters title={localizedStrings.products} style={{ marginTop: '10px' }}>
          <ListSelector
            selectedOptions={filter.productTypes}
            options={
              productTypes
                ? productTypes.map(pType => ({
                    id: pType.id,
                    value:
                      pType.localization && pType.localization[window.navigator.language]
                        ? pType.localization[window.navigator.language]
                        : pType.name
                  }))
                : []
            }
            onChange={products => setFilter(f => ({ ...f, productTypes: products }))}
            style={{ width: '100%' }}
          />
        </CustomAccordion>
        <CustomAccordion
          defaultExpanded={false}
          disableGutters
          title={localizedStrings.documentType}
          style={{ marginTop: '10px' }}
        >
          <ListSelector
            selectedOptions={filter.documentTypes}
            options={
              documentTypes
                ? documentTypes.map(pType => ({
                    id: pType.id,
                    value:
                      pType.localization && pType.localization[window.navigator.language]
                        ? pType.localization[window.navigator.language]
                        : pType.name
                  }))
                : []
            }
            onChange={products => setFilter(f => ({ ...f, documentTypes: products }))}
            style={{ width: '100%' }}
          />
        </CustomAccordion>
        <CustomAccordion defaultExpanded={false} disableGutters title={localizedStrings.languages} style={{ marginTop: '10px' }}>
          <ListSelector
            selectedOptions={filter.languageTypes}
            options={
              languageTypes
                ? languageTypes.map(pType => ({
                    id: pType.id,
                    value:
                      pType.localization && pType.localization[window.navigator.language]
                        ? pType.localization[window.navigator.language]
                        : pType.name
                  }))
                : []
            }
            onChange={languages => setFilter(f => ({ ...f, languageTypes: languages }))}
            style={{ width: '100%' }}
          />
        </CustomAccordion>
        <TextField
          id='filterDescriptionTextField'
          style={{ marginTop: '10px', width: '100%' }}
          label={localizedStrings.description}
          size='small'
          value={filter.description}
          variant='outlined'
          onChange={e => setFilter(f => ({ ...f, description: e.target.value }))}
        />
        <HasAccess env={env} allowedRoles={['Sales Admin', 'Sales Team Member', 'Packages Admin']}>
          <Box sx={{ display: 'flex', direction: 'row' }}>
            <CustomCheckbox
              id='filterArchivedCheckbox'
              checked={filter.showIsArchived}
              onChange={e => {
                if (e.target.checked === false) {
                  setFilter(f => ({ ...f, productTypes: f.productTypes.filter(pType => pType.isArchived === false) }))
                }

                setFilter(f => ({ ...f, showIsArchived: e.target.checked }))
              }}
            />
            <span style={{ paddingTop: '9px' }}>Show Archived</span>
          </Box>
        </HasAccess>
      </>
    )
  }
}
