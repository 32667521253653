import { CenteredDiv, WtxColors } from '@wavetronix/common-components'
import { ProductTableSection, NotesExclusionsSection } from './TemplateSections'
import dayjs from 'dayjs'

export const GENERIC_TITLE_INFO = ['projectName', 'preparedByUser', 'dealerAccount']
export default function GenericTitlePage({ htmlRef, submittalInfo, productIdMap, titlePageIncludes }) {
  return (
    <div ref={htmlRef}>
      {titlePageIncludes.titlePage ? (
        <div
          style={{
            paddingTop: '20px',
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingBottom: '15px',
            breakInside: 'avoid',
            pageBreakInside: 'avoid'
          }}
        >
          <img src='templates/wavetronix_logo_grey_gold.jpg' alt='Wavetronix' width='200px' />
          <div style={{ position: 'relative', height: '995px' }}>
            <div>
              <CenteredDiv>
                <h1 style={{ whiteSpace: 'pre-wrap' }}>Submittal Package</h1>
              </CenteredDiv>
              <CenteredDiv>
                <div style={{ whiteSpace: 'pre-wrap' }}>Project: {submittalInfo.projectName}</div>
              </CenteredDiv>
              <CenteredDiv>
                <div style={{ marginTop: '30px' }}>
                  Material List:
                  <ul>
                    {Object.values(productIdMap)
                      .sort((a, b) => (a.order > b.order ? 1 : -1))
                      .map((product, index) => {
                        return (
                          <li key={index} style={{ marginBottom: '10px' }}>
                            {product.name}
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </CenteredDiv>
            </div>

            {submittalInfo.preparedByUser && submittalInfo.preparedByUser.fullname !== '' ? (
              <div style={{ position: 'absolute', bottom: 40, left: 0 }}>
                <div>Prepared on {dayjs(submittalInfo.date).format('MMMM D, YYYY')} by:</div>
                <div>{submittalInfo.preparedByUser.fullname}</div>
                {submittalInfo.preparedByUser.title !== '' ? <div>{submittalInfo.preparedByUser.title}</div> : null}
                {submittalInfo.dealerAccount.phonenumber !== '' ? (
                  <div>Office: {submittalInfo.dealerAccount.phonenumber}</div>
                ) : null}
                {submittalInfo.preparedByUser.phonenumber !== '' ? (
                  <div>Cell: {submittalInfo.preparedByUser.phonenumber}</div>
                ) : null}
                {submittalInfo.dealerAccount.email !== '' ? <div>{submittalInfo.dealerAccount.email}</div> : null}
              </div>
            ) : null}
            <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
              <hr
                style={{
                  color: WtxColors.GOLD,
                  backgroundColor: WtxColors.GOLD
                }}
              />
              <div style={{ fontSize: '12px', textAlign: 'center' }}>
                1827 W 650 N, Springville, UT 84663, US • (801) 734-7200 • www.wavetronix.com{' '}
              </div>
            </div>
          </div>{' '}
        </div>
      ) : null}
      <ProductTableSection titlePageIncludes={titlePageIncludes} productIdMap={productIdMap} />
      <NotesExclusionsSection titlePageIncludes={titlePageIncludes} submittalInfo={submittalInfo} />
    </div>
  )
}
