import {
  TextField,
  Grid,
  Divider,
  Card,
  CardContent,
  Box,
  Grow,
  Autocomplete,
  IconButton,
  Tooltip,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@mui/material'
import CategoryIcon from '@mui/icons-material/Category'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import DataverseConnectorApi from '../../api/DataverseConnectorApi'
import GenericTitlePage, { GENERIC_TITLE_INFO } from '../templates/GenericTitlePage'
import GenericTitlePage2, { GENERIC2_TITLE_INFO } from '../templates/GenericTitlePage2'
import MDOTTitlePage, { MDOT_TITLE_INFO } from '../templates/MDOTTitlePage'
import CarolinasTitlePage, { CAROLINAS_TITLE_INFO } from '../templates/CarolinasTitlePage'
import FDOTHD, { FDOTHD_TITLE_INFO } from '../templates/FDOTHD'
import FDOTMatrixAdvance, { FDOTMATRIXADVANCE_TITLE_INFO } from '../templates/FDOTMatrixAdvance'
import FDOTMatrix, { FDOTMATRIX_TITLE_INFO } from '../templates/FDOTMatrix'
import FDOTExpanseXP20, { FDOTEXPANSEXP20_TITLE_INFO } from '../templates/FDOTExpanseXP20'
import FileUploader from './FileUploader'
import {
  CenteredDiv,
  PrimaryButton,
  CustomSelect,
  RegularButton,
  getMe,
  HasAccess,
  CustomCheckbox,
  SnackbarVariants,
  WtxColors
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import ProductTable from './TitlePageComponents/ProductTable'
import { UserInfoSection, AccountInfoSection, SimpleInfoSection } from './TitlePageComponents/InfoSections'
import { useMsal } from '@azure/msal-react'
import { env } from '../../index.js'
import dayjs from 'dayjs'
import { generatePdfFromHtml } from '../../utils/pdfUtils'

const TEMPLATE_OPTIONS = [
  'Basic Title Page',
  'Wavetronix Title Page',
  'Carolinas Title Page',
  'MDOT Title Page',
  'FDOT Matrix and Advance',
  'FDOT Matrix',
  'FDOT HD',
  'FDOT Expanse XP20'
]

const DEALER_TEMPLATE_OPTIONS = ['Basic Title Page', 'Wavetronix Title Page']

export const DEFAULT_SUBMITTAL_INFO = {
  template: '',
  projectName: '',
  location: '',
  engineer: '',
  date: dayjs(new Date()).format(),
  saleByUser: { fullname: '', email: '', title: '', phonenumber: '' },
  preparedByUser: { fullname: '', email: '', title: '', phonenumber: '' },
  operationsCoordUser: { fullname: '', email: '', title: '', phonenumber: '' },
  primaryContact: { fullname: '', email: '', phonenumber: '' },
  dealerContact: { fullname: '', email: '', phonenumber: '' },
  dealerAccount: { name: '', address: '', email: '', phonenumber: '' },
  endUserAccount: { name: '', address: '', email: '', phonenumber: '' },
  customerAccount: { name: '', address: '', email: '', phonenumber: '' },
  notes: ''
}

const GenerateSection = (title, infoData, updateSubmittalInfo, infoValue, isLoading, error, notFound) => {
  if (infoValue === 'location' || infoValue === 'engineer' || infoValue === 'projectName') {
    return (
      <SimpleInfoSection
        title={title}
        simpleInfo={infoData[infoValue]}
        updateSubmittalInfo={updateSubmittalInfo}
        infoValue={infoValue}
        isLoading={false}
        error={!infoData[infoValue]}
        notFound={infoData[infoValue] === ''}
      />
    )
  } else if (infoValue === 'endUserAccount' || infoValue === 'customerAccount' || infoValue === 'dealerAccount') {
    return (
      <AccountInfoSection
        title={title}
        account={infoData[infoValue]}
        updateSubmittalInfo={updateSubmittalInfo}
        infoValue={infoValue}
        isLoading={isLoading}
        error={error}
        notFound={notFound}
      />
    )
  } else if (infoValue === 'operationsCoordUser' || infoValue === 'preparedByUser') {
    return (
      <UserInfoSection
        title={title}
        user={infoData[infoValue]}
        updateSubmittalInfo={updateSubmittalInfo}
        infoValue={infoValue}
        isLoading={isLoading}
        error={error}
        notFound={notFound}
      />
    )
  } else {
    return <div>Contact the Gojira team to add {title}</div>
  }
}

export default function CreateOpportunityTitlePdf({
  opportunity,
  titlePage,
  setTitlePage,
  productIdMap,
  setProductIdMap,
  submittalInfo,
  setSubmittalInfo,
  setTitlePageIncludes,
  titlePageIncludes,
  productDocMap,
  htmlRef,
  productTableEditable = true
}) {
  const { enqueueSnackbar } = useSnackbar()
  const { instance, accounts } = useMsal()
  const [previewURL, setPreviewURL] = useState()
  const [clearFileUpload, setClearFileUpload] = useState(false)
  const [titleInfoOptions, setTitleInfoOptions] = useState(null)
  const [addProductState, setAddProductState] = useState(false)

  const {
    isLoading: meIsLoading,
    data: meData,
    error: meError
  } = useQuery({ queryKey: ['loggedUser'], queryFn: async () => await getMe(instance, env, accounts) })

  const { isLoading: opportunityDataIsLoading, data: opportunityData } = useQuery({
    queryKey: ['opportunityData', opportunity],
    queryFn: async () => {
      return await DataverseConnectorApi.getValueByEq('opportunities', 'opportunityid', opportunity.opportunityid).then(res =>
        res.value && res.value.length > 0 && res.value[0].name ? res.value[0] : null
      )
    },
    enabled: !!opportunity && !!opportunity.opportunityid && opportunity.opportunityid !== '' // Set 'enabled' to false if the parameter is null or an empty string
  })

  //Contact - Primary Contact (ie. Kelly Momaney)
  // const {
  //   data: primaryContactData,
  //   isLoading: primaryContactIsLoading,
  //   error: primaryContactError
  // } = useQuery({
  //   queryKey: ['primarycontact', opportunity],
  //   queryFn: async () =>
  //     await DataverseConnectorApi.getValueByEq('contacts', 'contactid', opportunity['_new_primarycontact_value']).then(res => {
  //       return res.value.length === 1 ? res.value[0] : null
  //     }),
  //   enabled: !!opportunity && !!opportunity['_new_primarycontact_value'] && opportunity['_new_primarycontact_value'] !== '' // Set 'enabled' to false if the parameter is null or an empty string
  // })

  //Contact - Dealer Contact (ie. Jeff Lenoff)
  // const {
  //   data: dealerContactData,
  //   isLoading: dealerContactIsLoading,
  //   error: dealerContactError
  // } = useQuery({
  //   queryKey: ['dealerContactData', opportunity],
  //   queryFn: async () =>
  //     await DataverseConnectorApi.getValueByEq('contacts', 'contactid', opportunity['_new_channelpartnercontact_value']).then(
  //       res => {
  //         return res.value.length === 1 ? res.value[0] : null
  //       }
  //     ),
  //   enabled:
  //     !!opportunity && !!opportunity['_new_channelpartnercontact_value'] && opportunity['_new_channelpartnercontact_value'] !== '' // Set 'enabled' to false if the parameter is null or an empty string
  // })

  //Contact - Primary Contact (ie. Kelly Momaney)
  const {
    data: currentContactData,
    isLoading: currentContactIsLoading,
    error: currentContactError
  } = useQuery({
    queryKey: ['currentContact', opportunity, meData],
    queryFn: async () =>
      await DataverseConnectorApi.getValueByContains('contacts', 'emailaddress1', encodeURIComponent(meData.email)).then(res => {
        return res.value.length === 1 ? res.value[0] : null
      }),
    enabled: !opportunity && !!meData && !!meData.email // Set 'enabled' to false if the parameter is null or an empty string
  })

  //User - Operations Coordinator (ie. Janet Markworth)
  const {
    data: operationsCoordUserData,
    isLoading: operationsCoordUserIsLoading,
    error: operationsCoordUserError
  } = useQuery({
    queryKey: ['operationsCoordUserData', opportunity],
    queryFn: async () =>
      await DataverseConnectorApi.getValueByEq(
        'systemusers',
        'systemuserid',
        opportunity['_new_operationscoordinator_value']
      ).then(res => {
        return res.value.length === 1 ? res.value[0] : null
      }),
    enabled:
      !!opportunity && !!opportunity['_new_operationscoordinator_value'] && opportunity['_new_operationscoordinator_value'] !== '' // Set 'enabled' to false if the parameter is null or an empty string
  })

  //User - submittal/opportunity creator? - (ie. Nalani Bird)
  const {
    data: preparedByUserData,
    isLoading: preparedByUserIsLoading,
    error: preparedByUserError
  } = useQuery({
    queryKey: ['createdBy', opportunity],
    queryFn: async () =>
      await DataverseConnectorApi.getValueByEq('systemusers', 'systemuserid', opportunity['_createdby_value']).then(res => {
        return res.value.length === 1 ? res.value[0] : null
      }),
    enabled: !!opportunity && !!opportunity['_createdby_value'] && opportunity['_createdby_value'] !== '' // Set 'enabled' to false if the parameter is null or an empty string
  })

  //User - current active user
  const {
    data: currentUserData,
    isLoading: currentUserIsLoading,
    error: currentUserError
  } = useQuery({
    queryKey: ['currentUser', opportunity, meData],
    queryFn: async () =>
      await DataverseConnectorApi.getValueByContains(
        'systemusers',
        'internalemailaddress',
        encodeURIComponent(meData.email)
      ).then(res => {
        return res.value.length === 1 ? res.value[0] : null
      }),
    enabled: !opportunity && !!meData && !!meData.email // Set 'enabled' to false if the parameter is null or an empty string
  })

  //User - sales person (ie. Brian Raboy)
  // const {
  //   data: salesUserData,
  //   isLoading: salesUserIsLoading,
  //   error: salesUserError
  // } = useQuery({
  //   queryKey: ['salesData', opportunity],
  //   queryFn: async () =>
  //     await DataverseConnectorApi.getValueByEq('systemusers', 'systemuserid', opportunity['_ownerid_value']).then(res => {
  //       return res.value.length === 1 ? res.value[0] : null
  //     }),
  //   enabled: !!opportunity && !!opportunity['_ownerid_value'] && opportunity['_ownerid_value'] !== '' // Set 'enabled' to false if the parameter is null or an empty string
  // })

  //Account - Dealer account (ie. Wavetronix California)
  const {
    data: dealerAccountData,
    isLoading: dealerAccountIsLoading,
    error: dealerAccountError
  } = useQuery({
    queryKey: ['dealerAccountData', opportunity],
    queryFn: async () =>
      await DataverseConnectorApi.getValueByEq('accounts', 'accountid', opportunity['_new_dealerid_value']).then(res => {
        return res.value.length === 1 ? res.value[0] : null
      }),
    enabled: !!opportunity && !!opportunity['_new_dealerid_value'] && opportunity['_new_dealerid_value'] !== '' // Set 'enabled' to false if the parameter is null or an empty string
  })

  //Account - End User account (ie. Caltrans District 3)
  const {
    data: endUserAccountData,
    isLoading: endUserAccountIsLoading,
    error: endUserAccountError
  } = useQuery({
    queryKey: ['endUserAccountData', opportunity],
    queryFn: async () =>
      await DataverseConnectorApi.getValueByEq('accounts', 'accountid', opportunity['_new_enduser_value']).then(res => {
        return res.value.length === 1 ? res.value[0] : null
      }),
    enabled: !!opportunity && !!opportunity['_new_enduser_value'] && opportunity['_new_enduser_value'] !== '' // Set 'enabled' to false if the parameter is null or an empty string
  })

  //Account - Purchasing Business account (ie. JAM Services, Inc)
  const {
    data: customerAccountData,
    isLoading: customerAccountIsLoading,
    error: customerAccountError
  } = useQuery({
    queryKey: ['customeraccountData', opportunity],
    queryFn: async () =>
      await DataverseConnectorApi.getValueByEq('accounts', 'accountid', opportunity['_customerid_value']).then(res => {
        return res.value.length === 1 ? res.value[0] : null
      }),
    enabled: !!opportunity && !!opportunity['_customerid_value'] && opportunity['_customerid_value'] !== '' // Set 'enabled' to false if the parameter is null or an empty string
  })

  // useEffect - all for opportunify info queries

  useEffect(() => {
    if (!preparedByUserData) {
      if (currentUserData) {
        setSubmittalInfo(info => ({
          ...info,
          preparedByUser: {
            fullname: currentUserData['fullname'] ? currentUserData['fullname'] : '',
            email: currentUserData['internalemailaddress'] ? currentUserData['internalemailaddress'] : '',
            title: currentUserData['title'] ? currentUserData['title'] : '',
            phonenumber: currentUserData['mobilephone'] ? currentUserData['mobilephone'] : ''
          }
        }))
      } else if (currentContactData) {
        setSubmittalInfo(info => ({
          ...info,
          preparedByUser: {
            fullname: currentContactData['fullname'] ? currentContactData['fullname'] : '',
            email: currentContactData['emailaddress1'] ? currentContactData['emailaddress1'] : '',
            title: '',
            phonenumber: currentContactData['mobilephone'] ? currentContactData['mobilephone'] : ''
          }
        }))
      } else if (meData) {
        setSubmittalInfo(info => ({
          ...info,
          preparedByUser: {
            fullname: `${meData.givenName} ${meData.surname}`,
            email: meData.email,
            title: '',
            phonenumber: ''
          }
        }))
      }
    }
  }, [setSubmittalInfo, currentContactData, currentUserData, preparedByUserData, meData])

  useEffect(() => {
    if (titlePage) {
      setPreviewURL(URL.createObjectURL(titlePage))
    } else {
      setPreviewURL()
      URL.revokeObjectURL(titlePage)
    }
  }, [titlePage])

  useEffect(() => {
    if (opportunityData) {
      setSubmittalInfo(info => ({
        ...info,
        projectName: opportunityData.name,
        location: opportunityData['new_location']
      }))
    }
  }, [opportunityData, setSubmittalInfo])

  useEffect(() => {
    if (preparedByUserData) {
      setSubmittalInfo(info => ({
        ...info,
        preparedByUser: {
          fullname: preparedByUserData['fullname'] ? preparedByUserData['fullname'] : '',
          email: preparedByUserData['internalemailaddress'] ? preparedByUserData['internalemailaddress'] : '',
          title: preparedByUserData['title'] ? preparedByUserData['title'] : '',
          phonenumber: preparedByUserData['mobilephone'] ? preparedByUserData['mobilephone'] : ''
        }
      }))
    }
  }, [preparedByUserData, setSubmittalInfo])

  // useEffect(() => {
  //   if (salesUserData) {
  //     setSubmittalInfo(info => ({
  //       ...info,
  //       saleByUser: {
  //         fullname: salesUserData['fullname'] ? salesUserData['fullname'] : '',
  //         email: salesUserData['internalemailaddress'] ? salesUserData['internalemailaddress'] : '',
  //         title: salesUserData['title'] ? salesUserData['title'] : '',
  //         phonenumber: salesUserData['mobilephone'] ? salesUserData['mobilephone'] : ''
  //       }
  //     }))
  //   }
  // }, [salesUserData, setSubmittalInfo])

  // useEffect(() => {
  //   if (primaryContactData) {
  //     setSubmittalInfo(info => ({
  //       ...info,
  //       primaryContact: {
  //         fullname: primaryContactData['fullname'] ? primaryContactData['fullname'] : '',
  //         email: primaryContactData['emailaddress1'] ? primaryContactData['emailaddress1'] : '',
  //         phonenumber: primaryContactData['mobilephone'] ? primaryContactData['mobilephone'] : ''
  //       }
  //     }))
  //   }
  // }, [primaryContactData, setSubmittalInfo])

  // useEffect(() => {
  //   if (dealerContactData) {
  //     setSubmittalInfo(info => ({
  //       ...info,
  //       dealerContact: {
  //         fullname: dealerContactData['fullname'] ? dealerContactData['fullname'] : '',
  //         email: dealerContactData['emailaddress1'] ? dealerContactData['emailaddress1'] : '',
  //         phonenumber: dealerContactData['mobilephone'] ? dealerContactData['mobilephone'] : ''
  //       }
  //     }))
  //   }
  // }, [dealerContactData, setSubmittalInfo])

  useEffect(() => {
    if (operationsCoordUserData) {
      setSubmittalInfo(info => ({
        ...info,
        operationsCoordUser: {
          fullname: operationsCoordUserData['fullname'] ? operationsCoordUserData['fullname'] : '',
          email: operationsCoordUserData['internalemailaddress'] ? operationsCoordUserData['internalemailaddress'] : '',
          title: operationsCoordUserData['title'] ? operationsCoordUserData['title'] : '',
          phonenumber: operationsCoordUserData['mobilephone'] ? operationsCoordUserData['mobilephone'] : ''
        }
      }))
    }
  }, [operationsCoordUserData, setSubmittalInfo])

  useEffect(() => {
    if (dealerAccountData) {
      setSubmittalInfo(info => ({
        ...info,
        dealerAccount: {
          name: dealerAccountData['name'] ? dealerAccountData['name'] : '',
          address: dealerAccountData['address1_composite'] ? dealerAccountData['address1_composite'] : '',
          phonenumber: dealerAccountData['address1_telephone1'] ? dealerAccountData['address1_telephone1'] : '',
          email: dealerAccountData['emailaddress1'] ? dealerAccountData['emailaddress1'] : ''
        }
      }))
    }
  }, [dealerAccountData, setSubmittalInfo])

  useEffect(() => {
    if (endUserAccountData) {
      setSubmittalInfo(info => ({
        ...info,
        endUserAccount: {
          name: endUserAccountData['name'] ? endUserAccountData['name'] : '',
          address: endUserAccountData['address1_composite'] ? endUserAccountData['address1_composite'] : '',
          phonenumber: endUserAccountData['address1_telephone1'] ? endUserAccountData['address1_telephone1'] : ''
        }
      }))
    }
  }, [endUserAccountData, setSubmittalInfo])

  useEffect(() => {
    if (customerAccountData) {
      setSubmittalInfo(info => ({
        ...info,
        customerAccount: {
          name: customerAccountData['name'] ? customerAccountData['name'] : '',
          address: customerAccountData['address1_composite'] ? customerAccountData['address1_composite'] : '',
          phonenumber: customerAccountData['address1_telephone1'] ? customerAccountData['address1_telephone1'] : ''
        }
      }))
    }
  }, [customerAccountData, setSubmittalInfo])

  useEffect(() => {
    const titleOptionsMap = {
      'Basic Title Page': GENERIC_TITLE_INFO,
      'Wavetronix Title Page': GENERIC2_TITLE_INFO,
      'Carolinas Title Page': CAROLINAS_TITLE_INFO,
      'MDOT Title Page': MDOT_TITLE_INFO,
      'FDOT Matrix and Advance': FDOTMATRIXADVANCE_TITLE_INFO,
      'FDOT Matrix': FDOTMATRIX_TITLE_INFO,
      'FDOT HD': FDOTHD_TITLE_INFO,
      'FDOT Expanse XP20': FDOTEXPANSEXP20_TITLE_INFO
    }

    if (submittalInfo.template) {
      setTitleInfoOptions(titleOptionsMap[submittalInfo.template] || null)
    }
  }, [submittalInfo.template])

  const generatePdf = async () => {
    await generatePdfFromHtml(htmlRef, `${submittalInfo.projectName}_TitlePage.pdf`, setTitlePage)
  }

  const generateTitlePage = async () => {
    setClearFileUpload(true)
    await generatePdf()
  }

  const addProduct = productId => {
    let prod = productDocMap[productId]

    setProductIdMap(oldmap => ({
      ...oldmap,
      [productId]: {
        count: 1,
        documents: prod.documents,
        name: prod.productInfo.name,
        productnumber: prod.productNumbers.join(' OR '),
        // bidItemNumber: null, TODO: BID NUMBER
        order: Math.max(...Object.values(oldmap).map(p => p.order)) + 1
      }
    }))
    enqueueSnackbar(`Successfully added ${prod.productInfo.name}`, SnackbarVariants.SUCCESS)
  }

  const removeProduct = product => {
    let foundId = ''
    let foundName = ''
    let foundProd = productIdMap[product.id]

    if (!foundProd) {
      //product from opportunity - mismatch id issue
      foundProd = productIdMap[product.productid]
      if (foundProd) {
        foundId = product.productid
        foundName = product.name
      } else {
        enqueueSnackbar(`Error deleting product - id not found`, SnackbarVariants.ERROR)
      }
    } else {
      foundId = product.id
      foundName = product.name
    }

    if (foundId !== '') {
      let newMap = { ...productIdMap }
      delete newMap[`${foundId}`]

      setProductIdMap({ ...newMap })
      enqueueSnackbar(`Successfully deleted ${foundName}`, SnackbarVariants.SUCCESS)
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={6}>
          {submittalInfo.template && submittalInfo.template !== '' ? (
            <>
              <HasAccess
                env={env}
                allowedRoles={['Sales Admin', 'Sales Team Member', 'Packages Admin']}
                unauthorizedControl={
                  <>
                    {titleInfoOptions ? (
                      <>
                        {titleInfoOptions.map((option, index) => {
                          return (
                            <div key={index}>
                              {
                                {
                                  projectName: GenerateSection(
                                    'Project Name',
                                    submittalInfo,
                                    setSubmittalInfo,
                                    'projectName',
                                    opportunityDataIsLoading,
                                    !submittalInfo.projectName,
                                    submittalInfo.projectName === ''
                                  ),
                                  preparedByUser: GenerateSection(
                                    'Prepared By',
                                    submittalInfo,
                                    setSubmittalInfo,
                                    'preparedByUser',
                                    preparedByUserIsLoading || currentContactIsLoading || currentUserIsLoading || meIsLoading,
                                    preparedByUserError || currentContactError || currentUserError || meError,
                                    !preparedByUserData && !currentContactData && !currentUserData && !meData
                                  ),
                                  dealerAccount: GenerateSection(
                                    'Dealer Account',
                                    submittalInfo,
                                    setSubmittalInfo,
                                    'dealerAccount',
                                    dealerAccountIsLoading,
                                    dealerAccountError,
                                    !dealerAccountData
                                  ),
                                  '': (
                                    <div>
                                      <CenteredDiv>Contact Gojira team to add {option}</CenteredDiv>
                                    </div>
                                  )
                                }[option]
                              }
                            </div>
                          )
                        })}
                      </>
                    ) : (
                      <div>Please select a template to view info options.</div>
                    )}
                  </>
                }
              >
                {titleInfoOptions ? (
                  <>
                    {titleInfoOptions.map((option, index) => {
                      return (
                        <div key={index}>
                          {
                            {
                              projectName: GenerateSection(
                                'Project Name',
                                submittalInfo,
                                setSubmittalInfo,
                                'projectName',
                                false,
                                !submittalInfo.projectName,
                                submittalInfo.projectName === ''
                              ),
                              location: GenerateSection(
                                'Location',
                                submittalInfo,
                                setSubmittalInfo,
                                'location',
                                false,
                                !submittalInfo.location,
                                submittalInfo.location === ''
                              ),
                              engineer: GenerateSection(
                                'Engineer',
                                submittalInfo,
                                setSubmittalInfo,
                                'engineer',
                                false,
                                !submittalInfo.engineer,
                                submittalInfo.engineer === ''
                              ),
                              endUserAccount: GenerateSection(
                                submittalInfo.template === 'Carolinas Title Page' ? 'Owner' : 'End User Account',
                                submittalInfo,
                                setSubmittalInfo,
                                'endUserAccount',
                                endUserAccountIsLoading,
                                endUserAccountError,
                                !endUserAccountData
                              ),
                              customerAccount: GenerateSection(
                                submittalInfo.template === 'Carolinas Title Page' ? 'Contractor' : 'Customer Account',
                                submittalInfo,
                                setSubmittalInfo,
                                'customerAccount',
                                customerAccountIsLoading,
                                customerAccountError,
                                !customerAccountData
                              ),
                              operationsCoordUser: GenerateSection(
                                'Operations Coordinator',
                                submittalInfo,
                                setSubmittalInfo,
                                'operationsCoordUser',
                                operationsCoordUserIsLoading,
                                operationsCoordUserError,
                                !operationsCoordUserData
                              ),
                              preparedByUser: GenerateSection(
                                'Prepared By',
                                submittalInfo,
                                setSubmittalInfo,
                                'preparedByUser',
                                preparedByUserIsLoading || currentContactIsLoading || currentUserIsLoading || meIsLoading,
                                preparedByUserError || currentContactError || currentUserError || meError,
                                !preparedByUserData && !currentContactData && !currentUserData && !meData
                              ),
                              dealerAccount: GenerateSection(
                                'Dealer Account',
                                submittalInfo,
                                setSubmittalInfo,
                                'dealerAccount',
                                dealerAccountIsLoading,
                                dealerAccountError,
                                !dealerAccountData
                              ),
                              '': (
                                <div>
                                  <CenteredDiv>Contact Gojira team to add {option}</CenteredDiv>
                                </div>
                              )
                            }[option]
                          }
                        </div>
                      )
                    })}
                  </>
                ) : null}

                {/* 
            NOT USED??
            <ContactInfoSection
              title={'Primary Contact'}
              contact={submittalInfo.primaryContact}
              updateSubmittalInfo={setSubmittalInfo}
              infoValue={'primaryContact'}
              isLoading={primaryContactIsLoading}
              error={primaryContactError}
              notFound={!primaryContactData}
            /> 
            <UserInfoSection
              title={'Sale By'}
              user={submittalInfo.saleByUser}
              updateSubmittalInfo={setSubmittalInfo}
              infoValue={'saleByUser'}
              isLoading={salesUserIsLoading}
              error={salesUserError}
              notFound={!salesUserData}
            />
            <ContactInfoSection
              title={'Dealer Contact'}
              contact={submittalInfo.dealerContact}
              updateSubmittalInfo={setSubmittalInfo}
              infoValue={'dealerContact'}
              isLoading={dealerContactIsLoading}
              error={dealerContactError}
              notFound={!dealerContactData}
            />
            */}
              </HasAccess>
              <TextField
                id='titlePageNotesTextField'
                label='Notes/Exclusions'
                value={submittalInfo.notes}
                onChange={e => setSubmittalInfo(info => ({ ...info, notes: e.target.value }))}
                sx={{ width: '100%', marginTop: '15px' }}
                size='small'
                multiline
                minRows={4}
                maxRows={10}
                inputProps={{ maxLength: 1600 }}
              />
              <div style={{ marginTop: '15px' }}>
                <Box sx={{ display: 'flex', direction: 'row', marginBottom: '10px' }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Grow in={addProductState}>
                    <Autocomplete
                      id='productAddAutocomplete'
                      sx={{ maxWidth: '300px', minWidth: '50%', marginRight: '5px' }}
                      size='small'
                      onChange={(event, value) => {
                        if (value) {
                          addProduct(value.id)
                        }
                      }}
                      options={
                        productDocMap
                          ? Object.keys(productDocMap).map(id => {
                              const productInfo = productDocMap[id].productInfo
                              const itemNumbers = productInfo.productItemNumbers
                              const labelSuffix = itemNumbers.length > 0 ? `: ${itemNumbers.join(', ')}` : ''

                              return {
                                label: `${productInfo.name} ${labelSuffix}`,
                                id: id
                              }
                            })
                          : []
                      }
                      isOptionEqualToValue={(option, someValue) => option.id === someValue.id}
                      renderInput={params => <TextField {...params} label='Product' />}
                    />
                  </Grow>
                  <Tooltip title='Add Products'>
                    <IconButton
                      id='addProductStateButton'
                      onClick={() => setAddProductState(s => s === false)}
                      sx={{ marginRight: '10px' }}
                    >
                      <CategoryIcon sx={{ color: addProductState === true ? WtxColors.IQ_BLUE : 'default' }} />
                    </IconButton>
                  </Tooltip>
                </Box>

                <ProductTable
                  productIdMap={productIdMap}
                  setProductIdMap={setProductIdMap}
                  editable={true && productTableEditable}
                  removeProduct={removeProduct}
                />
              </div>
            </>
          ) : (
            <div>
              <div style={{ marginBottom: '15px' }}>
                {GenerateSection(
                  'Project Name',
                  submittalInfo,
                  setSubmittalInfo,
                  'projectName',
                  false,
                  !submittalInfo.projectName,
                  submittalInfo.projectName === ''
                )}
              </div>
              <div style={{ marginTop: '15px' }}>
                <Box sx={{ display: 'flex', direction: 'row', marginBottom: '10px' }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Grow in={addProductState}>
                    <Autocomplete
                      id='productAddAutocomplete'
                      sx={{ maxWidth: '300px', minWidth: '50%', marginRight: '5px' }}
                      size='small'
                      onChange={(event, value) => {
                        if (value) {
                          addProduct(value.id)
                        }
                      }}
                      options={
                        productDocMap
                          ? Object.keys(productDocMap).map(id => {
                              const productInfo = productDocMap[id].productInfo
                              const itemNumbers = productInfo.productItemNumbers
                              const labelSuffix = itemNumbers.length > 0 ? `: ${itemNumbers.join(', ')}` : ''

                              return {
                                label: `${productInfo.name} ${labelSuffix}`,
                                id: id
                              }
                            })
                          : []
                      }
                      isOptionEqualToValue={(option, someValue) => option.id === someValue.id}
                      renderInput={params => <TextField {...params} label='Product' />}
                    />
                  </Grow>
                  <Tooltip title='Add Products'>
                    <IconButton
                      id='addProductStateButton'
                      onClick={() => setAddProductState(s => s === false)}
                      sx={{ marginRight: '10px' }}
                    >
                      <CategoryIcon sx={{ color: addProductState === true ? WtxColors.IQ_BLUE : 'default' }} />
                    </IconButton>
                  </Tooltip>
                </Box>

                <ProductTable
                  productIdMap={productIdMap}
                  setProductIdMap={setProductIdMap}
                  editable={true && productTableEditable}
                  removeProduct={removeProduct}
                />
              </div>
            </div>
          )}
        </Grid>
        <Grid item md={6}>
          <FileUploader
            prompt={'Upload Custom Title Page (PDF)'}
            clear={clearFileUpload}
            fileTypes={'application/pdf'}
            handleFile={async file => {
              setClearFileUpload(false)
              if (titlePage) {
                URL.revokeObjectURL(titlePage)
              }
              await setTitlePage(file)
            }}
          />
          <Divider>OR</Divider>
          <HasAccess
            env={env}
            allowedRoles={['Sales Admin', 'Sales Team Member', 'Packages Admin']}
            unauthorizedControl={
              <CustomSelect
                id='titlePageTemplateSelect'
                label='PDF Template'
                style={{ width: '100%', marginTop: '15px' }}
                value={submittalInfo.template}
                options={DEALER_TEMPLATE_OPTIONS}
                onChange={e => {
                  setClearFileUpload(true)
                  setTitlePage(null)
                  setSubmittalInfo(info => ({ ...info, template: e.target.value }))
                }}
              />
            }
          >
            <CustomSelect
              id='titlePageTemplateSelect'
              label='PDF Template'
              style={{ width: '100%', marginTop: '15px' }}
              value={submittalInfo.template}
              options={TEMPLATE_OPTIONS}
              onChange={e => {
                setClearFileUpload(true)
                setTitlePage(null)
                setSubmittalInfo(info => ({ ...info, template: e.target.value }))
              }}
            />
          </HasAccess>

          {titlePage && submittalInfo.template !== '' ? (
            <CenteredDiv>
              <RegularButton
                id='titlePageUnloadButton'
                disabled={submittalInfo.template === ''}
                style={{ width: '100%', marginTop: '10px' }}
                onClick={async () => {
                  URL.revokeObjectURL(titlePage)

                  setTitlePage(null)
                  setPreviewURL()
                }}
              >
                Unload Generated PDF
              </RegularButton>
            </CenteredDiv>
          ) : (
            <CenteredDiv>
              <PrimaryButton
                id='titlePageLoadButton'
                disabled={submittalInfo.template === '' || Object.values(titlePageIncludes).every(option => option === false)}
                style={{ width: '100%', marginTop: '10px' }}
                onClick={async () => {
                  await generateTitlePage()
                }}
              >
                Generate Title Page
              </PrimaryButton>
            </CenteredDiv>
          )}

          {titlePage ? (
            <>
              <iframe
                src={previewURL}
                title='Title Page Preview'
                style={{ display: 'block', height: '90vh', width: '48vw', marginTop: '15px' }}
              />
            </>
          ) : (
            <>
              <FormControl fullWidth>
                <CenteredDiv>
                  <FormLabel sx={{ margin: '15px 15px 0px 0px' }}>INCLUDE:</FormLabel>

                  <FormGroup sx={{ marginTop: '15px' }} row>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id='includeTitlePageCheckbox'
                          value={titlePageIncludes.titlePage}
                          checked={titlePageIncludes.titlePage}
                          onChange={event =>
                            setTitlePageIncludes(pageIncludes => ({ ...pageIncludes, titlePage: event.target.checked }))
                          }
                        />
                      }
                      label='Title Page'
                    />
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id='includeProductTableCheckbox'
                          value={titlePageIncludes.productTable}
                          checked={titlePageIncludes.productTable}
                          onChange={event =>
                            setTitlePageIncludes(pageIncludes => ({ ...pageIncludes, productTable: event.target.checked }))
                          }
                        />
                      }
                      label='Product Table'
                    />
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          id='includeNotesExclusionsCheckbox'
                          value={titlePageIncludes.notesExclusions}
                          checked={titlePageIncludes.notesExclusions}
                          onChange={event =>
                            setTitlePageIncludes(pageIncludes => ({ ...pageIncludes, notesExclusions: event.target.checked }))
                          }
                        />
                      }
                      label='Notes/Exclusions'
                    />
                  </FormGroup>
                </CenteredDiv>
              </FormControl>
              <Card sx={{ minHeight: '1100px', marginTop: '15px' }}>
                <CardContent sx={{ padding: '20px' }}>
                  {
                    {
                      'Basic Title Page': (
                        <GenericTitlePage
                          htmlRef={htmlRef}
                          submittalInfo={submittalInfo}
                          productIdMap={productIdMap}
                          titlePageIncludes={titlePageIncludes}
                        />
                      ),
                      'Wavetronix Title Page': (
                        <GenericTitlePage2
                          htmlRef={htmlRef}
                          submittalInfo={submittalInfo}
                          productIdMap={productIdMap}
                          titlePageIncludes={titlePageIncludes}
                        />
                      ),
                      'Carolinas Title Page': (
                        <CarolinasTitlePage
                          htmlRef={htmlRef}
                          submittalInfo={submittalInfo}
                          productIdMap={productIdMap}
                          titlePageIncludes={titlePageIncludes}
                        />
                      ),
                      'MDOT Title Page': (
                        <MDOTTitlePage
                          htmlRef={htmlRef}
                          submittalInfo={submittalInfo}
                          productIdMap={productIdMap}
                          titlePageIncludes={titlePageIncludes}
                        />
                      ),
                      'FDOT Matrix and Advance': (
                        <FDOTMatrixAdvance
                          htmlRef={htmlRef}
                          submittalInfo={submittalInfo}
                          productIdMap={productIdMap}
                          titlePageIncludes={titlePageIncludes}
                        />
                      ),
                      'FDOT Matrix': (
                        <FDOTMatrix
                          htmlRef={htmlRef}
                          submittalInfo={submittalInfo}
                          productIdMap={productIdMap}
                          titlePageIncludes={titlePageIncludes}
                        />
                      ),
                      'FDOT HD': (
                        <FDOTHD
                          htmlRef={htmlRef}
                          submittalInfo={submittalInfo}
                          productIdMap={productIdMap}
                          titlePageIncludes={titlePageIncludes}
                        />
                      ),
                      'FDOT Expanse XP20': (
                        <FDOTExpanseXP20
                          htmlRef={htmlRef}
                          submittalInfo={submittalInfo}
                          productIdMap={productIdMap}
                          titlePageIncludes={titlePageIncludes}
                        />
                      ),
                      '': (
                        <div>
                          <CenteredDiv>Title Page will display here. No title page uploaded or generated.</CenteredDiv>
                        </div>
                      )
                    }[submittalInfo.template]
                  }
                </CardContent>
              </Card>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
