import { WtxColors, CenteredDiv } from '@wavetronix/common-components'
import dayjs from 'dayjs'
import { ProductTableSection, NotesExclusionsSection } from './TemplateSections'

export const CAROLINAS_TITLE_INFO = [
  'projectName',
  'location',
  'engineer',
  'endUserAccount',
  'customerAccount',
  'operationsCoordUser',
  'preparedByUser'
]

export default function CarolinasTitlePage({ htmlRef, submittalInfo, productIdMap, titlePageIncludes }) {
  return (
    <div ref={htmlRef}>
      {titlePageIncludes.titlePage ? (
        <div
          style={{
            paddingTop: '20px',
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingBottom: '15px',
            breakInside: 'avoid',
            pageBreakInside: 'avoid'
          }}
        >
          <CenteredDiv>
            <img src='templates/wavetronix_logo_grey_gold.jpg' alt='Wavetronix' width='200px' />
          </CenteredDiv>
          <div style={{ position: 'relative', height: '995px', fontSize: '14px' }}>
            <div>
              <div style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold', fontSize: '18px' }}>Submittal Package</div>
              <p style={{ whiteSpace: 'pre-wrap', marginTop: '50px', display: 'flex' }}>
                <span>
                  <span style={{ fontWeight: 'bold' }}>PROJECT NAME:</span>
                  <span>{` ${submittalInfo.projectName}`}</span>
                </span>
              </p>
              <p style={{ whiteSpace: 'pre-wrap', display: 'flex' }}>
                <span>
                  <span style={{ fontWeight: 'bold' }}>LOCATION:</span>
                  <span>{` ${submittalInfo.location}`}</span>
                </span>
              </p>
              <p style={{ whiteSpace: 'pre-wrap', display: 'flex' }}>
                <span>
                  <span style={{ fontWeight: 'bold' }}>ENGINEER:</span>
                  <span>{` ${submittalInfo.engineer}`}</span>
                </span>
              </p>
              <p style={{ whiteSpace: 'pre-wrap', display: 'flex' }}>
                <span>
                  <span style={{ fontWeight: 'bold' }}>OWNER:</span>
                  <span>{` ${submittalInfo.endUserAccount.name}`}</span>
                </span>
              </p>
              <p style={{ whiteSpace: 'pre-wrap', display: 'flex' }}>
                <span>
                  <span style={{ fontWeight: 'bold' }}>CONTRACTOR:</span>
                  <span>{` ${submittalInfo.customerAccount.name}`}</span>
                </span>
              </p>
              <p style={{ whiteSpace: 'pre-wrap', display: 'flex' }}>
                <span>
                  <span style={{ fontWeight: 'bold' }}>DATE:</span>
                  <span>{` ${dayjs(submittalInfo.date).format('MMMM D, YYYY')}`}</span>
                </span>
              </p>
            </div>

            <div style={{ position: 'absolute', bottom: 50, left: 0 }}>
              {submittalInfo.operationsCoordUser && submittalInfo.operationsCoordUser.fullname !== '' ? (
                <div>
                  <p style={{ fontWeight: 'bold' }}>Submitted By:</p>
                  <div>{submittalInfo.operationsCoordUser.fullname}</div>
                  {submittalInfo.operationsCoordUser.title !== '' ? <div>{submittalInfo.operationsCoordUser.title}</div> : null}
                  {submittalInfo.operationsCoordUser.phonenumber !== '' ? (
                    <div>{submittalInfo.operationsCoordUser.phonenumber}</div>
                  ) : null}
                  {submittalInfo.operationsCoordUser.email !== '' ? <div>{submittalInfo.operationsCoordUser.email}</div> : null}
                </div>
              ) : null}

              {submittalInfo.preparedByUser && submittalInfo.preparedByUser.fullname !== '' ? (
                <div style={{ marginTop: '40px' }}>
                  <p style={{ fontWeight: 'bold' }}>Prepared By:</p>
                  <div>{submittalInfo.preparedByUser.fullname}</div>
                  {submittalInfo.preparedByUser.title !== '' ? <div>{submittalInfo.preparedByUser.title}</div> : null}
                  {submittalInfo.preparedByUser.phonenumber !== '' ? <div>{submittalInfo.preparedByUser.phonenumber}</div> : null}
                  {submittalInfo.preparedByUser.email !== '' ? <div>{submittalInfo.preparedByUser.email}</div> : null}
                </div>
              ) : null}
            </div>
            <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
              <hr
                style={{
                  color: WtxColors.GOLD,
                  backgroundColor: WtxColors.GOLD
                }}
              />
              <div style={{ fontSize: '12px', textAlign: 'center' }}>
                1827 W 650 N, Springville, UT 84663, US • (801) 734-7200 • www.wavetronix.com{' '}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <ProductTableSection titlePageIncludes={titlePageIncludes} productIdMap={productIdMap} />
      <NotesExclusionsSection titlePageIncludes={titlePageIncludes} submittalInfo={submittalInfo} />
    </div>
  )
}
