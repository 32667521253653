import { CenteredDiv, WtxColors } from '@wavetronix/common-components'
import { ProductTableSection, NotesExclusionsSection } from './TemplateSections'
import dayjs from 'dayjs'

export const MDOT_TITLE_INFO = ['projectName', 'preparedByUser', 'dealerAccount']

export default function MDOTTitlePage({ htmlRef, submittalInfo, productIdMap, titlePageIncludes }) {
  return (
    <div ref={htmlRef}>
      {titlePageIncludes.titlePage ? (
        <div
          style={{
            paddingTop: '20px',
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingBottom: '15px',
            breakInside: 'avoid',
            pageBreakInside: 'avoid'
          }}
        >
          <img src='templates/wavetronix_logo_grey_gold.jpg' alt='Wavetronix' width='200px' />
          <div style={{ position: 'relative', minHeight: '990px' }}>
            <div>
              <CenteredDiv>
                <h1 style={{ whiteSpace: 'pre-wrap', fontWeight: 'normal' }}>Submittal Package</h1>
              </CenteredDiv>
              <CenteredDiv>
                <h1
                  style={{
                    whiteSpace: 'pre-wrap',
                    fontSize: '22px',
                    fontWeight: 'normal',
                    marginTop: '2px',
                    textAlign: 'center'
                  }}
                >
                  {submittalInfo.projectName}
                </h1>
              </CenteredDiv>
            </div>

            <div style={{ whiteSpace: 'pre-wrap', textAlign: 'center', marginTop: '40px' }}>
              <div>
                Thank you for the opportunity to provide this submittal package. Wavetronix equipment listed below and detailed in
                this package are for this project and are based on the documents created by WSP dated{' '}
                {dayjs(submittalInfo.date).format('M/D/YYYY')}.
              </div>
            </div>
            <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
              {submittalInfo.preparedByUser && submittalInfo.preparedByUser.fullname !== '' ? (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <div>Prepared on {dayjs(submittalInfo.date).format('MMMM D, YYYY')}</div>
                  <div>by: {submittalInfo.preparedByUser.fullname}</div>
                  {submittalInfo.preparedByUser.title !== '' ? <div>{submittalInfo.preparedByUser.title}</div> : null}
                  {submittalInfo.dealerAccount.phonenumber !== '' ? (
                    <div>Office: {submittalInfo.dealerAccount.phonenumber}</div>
                  ) : null}
                  {submittalInfo.preparedByUser.phonenumber !== '' ? (
                    <div>Cell: {submittalInfo.preparedByUser.phonenumber}</div>
                  ) : null}
                  {submittalInfo.dealerAccount.email !== '' ? <div>{submittalInfo.dealerAccount.email}</div> : null}
                </div>
              ) : null}
              <p style={{ textAlign: 'center' }}>This submittal includes the highlighted components only.</p>

              <hr
                style={{
                  color: WtxColors.GOLD,
                  backgroundColor: WtxColors.GOLD
                }}
              />
              <div style={{ fontSize: '12px', textAlign: 'center' }}>
                1827 W 650 N, Springville, UT 84663, US • (801) 734-7200 • www.wavetronix.com{' '}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <ProductTableSection titlePageIncludes={titlePageIncludes} productIdMap={productIdMap} />
      <NotesExclusionsSection titlePageIncludes={titlePageIncludes} submittalInfo={submittalInfo} />
    </div>
  )
}
