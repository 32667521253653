import { Box, CircularProgress, Collapse, Divider, IconButton, Snackbar, SnackbarContent, Stack, TextField } from '@mui/material'
import {
  PrimaryButton,
  PersistantFilterDiv,
  CustomDataGrid,
  CenteredDiv,
  ErrorMessage,
  useWtxLocalization
} from '@wavetronix/common-components'
import { useState, useEffect, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import CategoriesFilterDrawer, { DEFAULT_PRODUCT_FILTER, filterCrmCategories } from '../drawers/CategoriesFilterDrawer'
import CategoriesApi from '../../api/CategoriesApi'
import CrmProductsApi from '../../api/CrmProductsApi'
import { useMsal } from '@azure/msal-react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { clearAllCache } from '../../utils/cacheUtils'

export default function ProductDocumentBundler({ productDocMap }) {
  const { instance, accounts } = useMsal()
  let localizedStrings = useWtxLocalization()
  const [filter, setFilter] = useState(DEFAULT_PRODUCT_FILTER)
  const [selectedRows, setSelectedRows] = useState([])
  const [productIdMap, setProductIdMap] = useState({})
  const [collapseProductList, setCollapseProductList] = useState(false)

  const { data, error, isLoading } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => await CategoriesApi.getCategories(instance, accounts)
  })
  const { data: crmProducts } = useQuery({
    queryKey: ['crmProducts'],
    queryFn: async () => await CrmProductsApi.getCrmProducts(instance, accounts)
  })

  const categoryFamilies = useMemo(() => {
    let res = []
    if (data) {
      res =
        filter.showIsArchived === false
          ? data.filter(c => c.categoryType === 'Product Family' && c.isArchived === false)
          : data.filter(c => c.categoryType === 'Product Family')
    }
    return res.sort((a, b) => (a.order > b.order ? 1 : -1))
  }, [filter, data])

  useEffect(() => {
    if (selectedRows) {
      setProductIdMap(oldMap => {
        let productMap = {}
        let count = 1
        for (let row of selectedRows) {
          if (productDocMap[row.id]) {
            productMap[row.id] = {
              name: row.name,
              count: oldMap[row.id] ? oldMap[row.id].count : 1,
              productnumber: row.productItemNumbers.join(' OR '),
              documents: { ...productDocMap[row.id].documents },
              order: count
            }
            count += 1
          }
        }

        return productMap
      })
    }
  }, [selectedRows, productDocMap])

  const columns = [
    {
      headerName: 'Qty',
      field: 'count',
      flex: 0.5,
      renderCell: value => {
        if (productIdMap && productIdMap[value.row.id]) {
          return (
            <TextField
              inputProps={{ min: 0 }}
              type='number'
              sx={{ margin: '5px' }}
              size='small'
              value={productIdMap && productIdMap[value.row.id] ? productIdMap[value.row.id].count : ''}
              onChange={e =>
                setProductIdMap(map => ({ ...map, [value.row.id]: { ...map[value.row.id], count: e.target.value } }))
              }
            />
          )
        } else {
          return null
        }
      }
    },
    {
      headerName: localizedStrings.name,
      field: 'name',
      flex: 2
    },
    {
      headerName: 'Product Item Numbers',
      field: 'productItemNumbers',
      flex: 1,
      valueGetter: u => u.value.join(', ')
    },
    {
      headerName: localizedStrings.productFamily,
      field: 'family',
      flex: 1
    }
  ]

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }
  if (isLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }

  return (
    <>
      <PersistantFilterDiv
        page={
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <div style={{ marginRight: '15px', marginTop: '7px' }}>{selectedRows.length} products selected</div>
              <PrimaryButton
                id='createPackageButton'
                style={{ marginRight: '15px' }}
                disabled={!(selectedRows && selectedRows.length > 0)}
                onClick={() => {
                  clearAllCache()

                  localStorage.setItem('productIdMap', JSON.stringify(productIdMap))
                  localStorage.setItem('selectedPackage', null)

                  window.open(`/manualpackage`, '_blank')
                }}
              >
                Create Package
              </PrimaryButton>
            </Box>
            <CustomDataGrid
              rows={
                crmProducts ? filterCrmCategories(filter, crmProducts).sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1)) : []
              }
              style={{ marginLeft: '24px', marginTop: '15px' }}
              loading={isLoading}
              columns={columns}
              onColumnHeaderClick={e =>
                e.field === '__check__' ? setSelectedRows(selectedRows.length > 0 ? [] : crmProducts) : null
              }
              checkboxSelection
              keepNonExistentRowsSelected
              onSelectionModelChange={ids => {
                const selectedIDs = [...new Set(ids)]
                const selectedRows = crmProducts
                  .filter(row => selectedIDs.includes(row.id))
                  .sort((a, b) => (selectedIDs.indexOf(a.id) > selectedIDs.indexOf(b.id) ? 1 : -1))
                setSelectedRows(selectedRows)
              }}
            />
          </>
        }
        drawer={
          <CategoriesFilterDrawer
            filter={filter}
            setFilter={setFilter}
            category={'Product'}
            categoryFamlies={categoryFamilies}
            isCrm={true}
          />
        }
        resetFilter={() => setFilter(DEFAULT_PRODUCT_FILTER)}
      />
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={selectedRows.length > 0}>
        <SnackbarContent
          sx={{
            maxWidth: '55px',
            '.MuiSnackbarContent-message': { width: '100%' },
            '.MuiSnackbarContent-action': {
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderTop: '1px solid white',
              marginRight: '0px'
            }
          }}
          action={
            <>
              {collapseProductList ? 'Show Selected Products' : 'Hide Selected Products'}
              <IconButton
                id='collapseProductListButton'
                sx={{ color: '#eee' }}
                onClick={() => setCollapseProductList(!collapseProductList)}
                aria-label='expand'
                size='large'
              >
                {collapseProductList ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </>
          }
          message={
            <div style={{ maxHeight: 500, overflow: 'auto' }}>
              <Collapse in={!collapseProductList}>
                <Stack spacing={2} divider={<Divider sx={{ bgcolor: '#eee' }} flexItem variant='fullWidth' />}>
                  {selectedRows.map(item => {
                    return (
                      <CenteredDiv sx={{ textAlign: 'center' }} key={item.id}>
                        {item.name}
                      </CenteredDiv>
                    )
                  })}
                </Stack>
              </Collapse>
            </div>
          }
        />
      </Snackbar>
    </>
  )
}
