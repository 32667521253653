import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'
import { Buffer } from 'buffer'

let instance = null

class DocumentsApi {
  getMyDocuments = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.documentsURL}/documents/mine`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }

  downloadDocument = async (msal, accounts, doc) => {
    let token = await acquireAccessToken(msal, accounts, env)
    var form = new FormData()
    form.append('token', token)
    form.append('useMime', false)
    return await axios({
      url: `${env.urls.documentsURL}/documents/file/throughtoken/${doc.id}`,
      method: 'POST',
      data: form,
      responseType: 'blob',
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      const href = URL.createObjectURL(response.data)

      // create "a" HTLM element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', doc.fileName) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }

  downloadDocumentThroughPackage = async docId => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.packagesUserName}:${env.basicAuthentication.packagesPassword}`
    ).toString('base64')
    var form = new FormData()
    form.append('useMime', false)
    return await axios({
      url: `${env.urls.documentsURL}/documents/file/throughpackage/${docId}`,
      method: 'POST',
      data: form,
      responseType: 'blob',
      headers: { Authorization: `Basic ${credentials}` }
    }).then(response => {
      return response.data
    })
  }
}
const getInstance = () => {
  if (instance == null) {
    instance = new DocumentsApi()
  }
  return instance
}

export default getInstance()
