import { Tab, Tabs } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import '../index.css'
import { env } from '../index.js'
import { useWtxLocalization, WtxColors, HasAccess } from '@wavetronix/common-components'

const classes = {
  tab: {
    textTransform: 'none',
    fontFamily: 'Klavika',
    fontSize: '16px',
    alignItems: 'end',
    padding: '0px 16px 0px 0px',
    color: 'black'
  },
  tabParent: {
    minWidth: '190px'
  }
}

export default function PageNavbar(props) {
  const navigate = useNavigate()
  let localizedStrings = useWtxLocalization()

  const onChange = path => {
    if (window.location.pathname !== `/${path}`) {
      navigate(`/${path}`)
    }
    props.onChangeCallback(path)
  }

  return (
    <HasAccess
      env={env}
      allowedRoles={['Sales Team Member', 'Sales Admin', 'Packages Admin']}
      unauthorizedControl={
        <HasAccess
          env={env}
          allowedRoles={['Dealer']}
          unauthorizedControl={
            <Tabs
              value={props.value}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: WtxColors.IQ_BLUE
                }
              }}
              orientation='vertical'
              sx={classes.tabParent}
            >
              <Tab id='documentsTab' sx={classes.tab} label={localizedStrings.documents} onClick={() => onChange('documents')} />
              <Tab id='searchTab' sx={classes.tab} label={localizedStrings.search} onClick={() => onChange('search')} />
            </Tabs>
          }
        >
          <Tabs
            value={props.value}
            TabIndicatorProps={{
              sx: {
                backgroundColor: WtxColors.IQ_BLUE
              }
            }}
            orientation='vertical'
            sx={classes.tabParent}
          >
            <Tab id='documentsTab' sx={classes.tab} label={localizedStrings.documents} onClick={() => onChange('documents')} />
            <Tab id='searchTab' sx={classes.tab} label={localizedStrings.search} onClick={() => onChange('search')} />
            <Tab id='packagesTab' sx={classes.tab} label={'Packages'} onClick={() => onChange('packages')} />
            <Tab id='productsTab' sx={classes.tab} label={'Products'} onClick={() => onChange('products')} />
          </Tabs>
        </HasAccess>
      }
    >
      <Tabs
        value={props.value}
        TabIndicatorProps={{
          sx: {
            backgroundColor: WtxColors.IQ_BLUE
          }
        }}
        orientation='vertical'
        sx={classes.tabParent}
      >
        <Tab id='documentsTab' sx={classes.tab} label={localizedStrings.documents} onClick={() => onChange('documents')} />
        <Tab id='searchTab' sx={classes.tab} label={localizedStrings.search} onClick={() => onChange('search')} />
        <Tab id='packagesTab' sx={classes.tab} label={'Packages'} onClick={() => onChange('packages')} />
        <Tab id='productsTab' sx={classes.tab} label={'Products'} onClick={() => onChange('products')} />
        <Tab
          id='opportunitiesTab'
          sx={classes.tab}
          label={localizedStrings.bundleManager}
          onClick={() => onChange('opportunities')}
        />
      </Tabs>
    </HasAccess>
  )
}
