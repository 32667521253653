import { useState, useRef, useMemo } from 'react'
import { Snackbar } from '@mui/material'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { createPDF, mergePDF, pdfArrayToBlob } from 'pdf-actions'
import { saveAs } from 'file-saver'
import { addPageNumbers } from '../../utils/pdfUtils.js'
import { WtxColors } from '@wavetronix/common-components'

import { useCrossTabState } from '../customhooks/useCrossTabState'
import OpportunityBundleModal from './OpportunityBundleModal'
import PackageBundleModal from './PackageBundleModal'
import { DEFAULT_SUBMITTAL_INFO } from '../controls/CreateOpportunityTitlePdf'
import CategoriesApi from '../../api/CategoriesApi'
import { DOCUMENTTYPE_OPTIONS } from '../controls/SelectOpportunityDocuments'
import { getVideoType } from '../../utils/stringUtils'
import { env } from '../../index.js'
import DocumentsApi from '../../api/DocumentsApi'

export const STATE_OPTIONS = {
  NOTSTARTED: 0,
  LOADING: 1,
  SUCCESS: 2,
  ERROR: 3
}

export const TITLEPAGE_INCLUDE = {
  titlePage: true,
  productTable: false,
  notesExclusions: true
}

export const STEPS = ['Title Page', 'Select Documents', 'Create PDF']

export default function BundleWrapperModal({ type, productDocMap, categoriesMap }) {
  const { instance, accounts } = useMsal()
  const htmlRef = useRef(null)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [titlePage, setTitlePage] = useState(null)
  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [warningModal, setWarningModal] = useState(false)

  const [selectedDocs, setSelectedDocs] = useCrossTabState('selectedDocs', {})
  const [activeStep, setActiveStep] = useCrossTabState('activeStep', 0)
  const [submittalInfo, setSubmittalInfo] = useCrossTabState('submittalInfo', DEFAULT_SUBMITTAL_INFO)
  const [productIdMap, setProductIdMap] = useCrossTabState('productIdMap', null)
  const [titlePageIncludes, setTitlePageIncludes] = useCrossTabState('titlePageIncludes', TITLEPAGE_INCLUDE)

  const { data: categories } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      let categories = await CategoriesApi.getCategories(instance, accounts)
      return categories
    }
  })

  const LANGUAGE_OPTIONS = useMemo(() => {
    let langTypes = []
    if (categories) {
      langTypes = categories.filter(c => c.categoryType === 'Language')

      //set filter default, by first finding if there is a language that matches (should always include doc with matching base prefix) if not then don't set filter
      let browserLang = langTypes.filter(
        l =>
          l.name === window.navigator.language ||
          (l.name.length >= 2 && window.navigator.language.length === 2
            ? l.name.slice(0, 2) === window.navigator.language
            : false) ||
          (l.name.length === 2 && window.navigator.language.length >= 2
            ? window.navigator.language.slice(0, 2) === l.name
            : false)
      )

      setSelectedLanguages(browserLang)
    }
    return langTypes
  }, [categories])

  const DEFAULT_DOC_ORDER = useMemo(() => {
    if (productIdMap && categoriesMap) {
      let count = 1
      let docOrderMap = {}
      for (let product of Object.values(productIdMap).sort((a, b) => (a.order > b.order ? 1 : -1))) {
        for (let doc of Object.values(product.documents)
          .filter(
            d =>
              d.language.filter(p => selectedLanguages.map(fp => fp.id).includes(p)).length > 0 || selectedLanguages.length === 0
          )
          .sort((a, b) => {
            if (categoriesMap && categoriesMap[a.documentType] && categoriesMap[b.documentType]) {
              return DOCUMENTTYPE_OPTIONS.indexOf(categoriesMap[a.documentType].name) >
                DOCUMENTTYPE_OPTIONS.indexOf(categoriesMap[b.documentType].name)
                ? 1
                : -1
            } else {
              return 0
            }
          })) {
          docOrderMap[doc.id] = { fileName: doc.fileName, order: count }
          count += 1
        }
      }
      return docOrderMap
    }
  }, [productIdMap, selectedLanguages, categoriesMap])

  const handleNext = async () => {
    let currentStep = activeStep

    setActiveStep(prevActiveStep => prevActiveStep + 1)

    if (currentStep === 1) {
      handleDocDownloadandPdfTranslation()
    } else if (currentStep === 2) {
      handleMergePdf()
      setWarningModal(true)
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleMergePdf = async () => {
    //should download bundled pdf
    let docsArray = Object.values(selectedDocs)
      .filter(doc => !getVideoType(doc.id).isSuccess && doc.pdfTranslationState === STATE_OPTIONS.SUCCESS)
      .sort((a, b) => {
        if (Number(a.order) > Number(b.order)) {
          return 1
        } else {
          return -1
        }
      })
      .map(docInfo => docInfo.docPDF)

    //add title page to beginning of docsArray
    if (Object.values(titlePageIncludes).includes(true) && titlePage) {
      await createPDF
        .PDFDocumentFromFile(titlePage)
        .then(pdfFile => docsArray.unshift(pdfFile))
        .catch(err => {
          console.log(err)
        })
    }

    const mergedPdf = await mergePDF(docsArray) //merge array of PDFDocuments Objects

    /*Parameters
    fileDoc is a PDFDocument Object
    textSize? : optional 16 by default is a integer
    */
    const numberedPdf = await addPageNumbers(mergedPdf) //numbers pages of PDFDocument Object

    const mergedPdfFile = await numberedPdf.save() // saves PDFDocument Object to File
    const pdfBlob = pdfArrayToBlob(mergedPdfFile)

    saveAs(pdfBlob, `${env.basicAuthentication.packageCompany} - Submittal - ${submittalInfo.projectName}.pdf`)
  }

  const handleDocDownloadandPdfTranslation = async () => {
    setButtonsDisabled(true)

    await Promise.all(
      Object.keys(selectedDocs)
        .filter(docId => !getVideoType(docId).isSuccess)
        .map(doc => {
          setSelectedDocs(docs => ({ ...docs, [doc]: { ...docs[doc], fileDownloadState: STATE_OPTIONS.LOADING } }))
          return DocumentsApi.downloadDocumentThroughPackage(doc)
            .then(async res => {
              setSelectedDocs(docs => ({
                ...docs,
                [doc]: {
                  ...docs[doc],
                  fileDownloadState: STATE_OPTIONS.SUCCESS,
                  pdfTranslationState: STATE_OPTIONS.LOADING,
                  docFile: res
                }
              }))

              return res
            })
            .catch(err => {
              console.log(err)
              setSelectedDocs(docs => ({ ...docs, [doc]: { ...docs[doc], fileDownloadState: STATE_OPTIONS.ERROR } }))
            })
            .then(async res => {
              let file = new File([res], doc)
              await createPDF
                .PDFDocumentFromFile(file)
                .then(pdfFile => {
                  setSelectedDocs(docs => ({
                    ...docs,
                    [doc]: {
                      ...docs[doc],
                      pdfTranslationState: STATE_OPTIONS.SUCCESS,
                      docPDF: pdfFile
                    }
                  }))
                })
                .catch(err => {
                  console.log(err)
                  setSelectedDocs(docs => ({ ...docs, [doc]: { ...docs[doc], pdfTranslationState: STATE_OPTIONS.ERROR } }))
                })
            })
        })
    )

    setButtonsDisabled(false)
  }

  const deactivateNext = useMemo(() => {
    return activeStep === STEPS.length - 1
      ? buttonsDisabled
      : (activeStep === 1 && Object.keys(selectedDocs).length === 0) ||
          submittalInfo.projectName === '' ||
          (Object.values(titlePageIncludes).includes(true) && !titlePage)
  }, [activeStep, submittalInfo, titlePageIncludes, titlePage, buttonsDisabled, selectedDocs])

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          sx: {
            background: WtxColors.INNOVATION_RED
          }
        }}
        open={Object.values(titlePageIncludes).includes(true) && !titlePage && submittalInfo.template !== ''}
        message={
          activeStep === 0
            ? `No title page generated even though you have indicated you want one. Please GENERATE, UPLOAD, or deselect all the INCLUDE options.`
            : `No title page generated even though you have indicated you want one. Please navigate to beginning and REGENERATE, REUPLOAD, or deselect all the INCLUDE options.`
        }
      />
      {type === 'opportunity' ? (
        <OpportunityBundleModal
          productDocMap={productDocMap}
          selectedDocs={selectedDocs}
          setSelectedDocs={setSelectedDocs}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          submittalInfo={submittalInfo}
          setSubmittalInfo={setSubmittalInfo}
          productIdMap={productIdMap}
          setProductIdMap={setProductIdMap}
          titlePageIncludes={titlePageIncludes}
          setTitlePageIncludes={setTitlePageIncludes}
          buttonsDisabled={buttonsDisabled}
          setButtonsDisabled={setButtonsDisabled}
          titlePage={titlePage}
          setTitlePage={setTitlePage}
          selectedLanguages={selectedLanguages}
          setSelectedLanguages={setSelectedLanguages}
          warningModal={warningModal}
          setWarningModal={setWarningModal}
          LANGUAGE_OPTIONS={LANGUAGE_OPTIONS}
          DEFAULT_DOC_ORDER={DEFAULT_DOC_ORDER}
          htmlRef={htmlRef}
          deactivateNext={deactivateNext}
          handleNext={handleNext}
          handleBack={handleBack}
          categoriesMap={categoriesMap}
        />
      ) : type === 'package' ? (
        <PackageBundleModal
          productDocMap={productDocMap}
          selectedDocs={selectedDocs}
          setSelectedDocs={setSelectedDocs}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          submittalInfo={submittalInfo}
          setSubmittalInfo={setSubmittalInfo}
          productIdMap={productIdMap}
          setProductIdMap={setProductIdMap}
          titlePageIncludes={titlePageIncludes}
          setTitlePageIncludes={setTitlePageIncludes}
          buttonsDisabled={buttonsDisabled}
          setButtonsDisabled={setButtonsDisabled}
          titlePage={titlePage}
          setTitlePage={setTitlePage}
          selectedLanguages={selectedLanguages}
          setSelectedLanguages={setSelectedLanguages}
          warningModal={warningModal}
          setWarningModal={setWarningModal}
          htmlRef={htmlRef}
          LANGUAGE_OPTIONS={LANGUAGE_OPTIONS}
          DEFAULT_DOC_ORDER={DEFAULT_DOC_ORDER}
          deactivateNext={deactivateNext}
          handleNext={handleNext}
          handleBack={handleBack}
          categoriesMap={categoriesMap}
        />
      ) : (
        <div>Bad type option passed to BundleWrapperModal</div>
      )}
    </>
  )
}
