/*
Custom Solution from pdf-actions library function addPageNumbers
https://github.com/ManasMadan/pdf-actions
*/
import fontkit from '@pdf-lib/fontkit'
import html2pdf from 'html2pdf.js'
import { env } from '../index.js'

const { PageSizes } = require('pdf-lib')
const url = 'fonts/KlavikaWebBasicLight.otf'

const marginValuesNumber = {
  Recommended: 10,
  Small: 5,
  Big: 15
}

const marginIndexPagePosition = {
  b: 3,
  t: 1
}

const COMPANY = {
  wavetronix: 'Wavetronix',
  islandradar: 'Island Radar',
  fathom: 'Fathom'
}

const getTextPosition = (value, width, height, marginValues, textSize, pageNumberPosition, customFont) => {
  const text = value.toString()
  const textWidth = customFont.widthOfTextAtSize(text, textSize)
  const textHeight = customFont.heightAtSize(textSize)

  let textPositionX = 0,
    textPositionY = 0

  if (pageNumberPosition[0] === 'b') {
    textPositionY = (textHeight - marginValues[1] - marginValues[3]) / 2
  }
  if (pageNumberPosition[0] === 't') {
    textPositionY = height - textHeight - marginValues[1] / 2 - marginValues[3] / 2
  }

  if (pageNumberPosition[2] === 'l') {
    textPositionX = textWidth * 4
  }
  if (pageNumberPosition[2] === 'c') {
    textPositionX = width / 2 - textWidth / 2
  }
  if (pageNumberPosition[2] === 'r') {
    textPositionX = width - textWidth * 4
  }

  return { text, textPositionX, textPositionY }
}

const drawTextOnPage = (
  page,
  totalPageCount,
  value,
  textSize,
  customFont,
  pageNumberPosition = 'b-c',
  margin = 'Small',
  marginValues = [0, 0, 0, 0]
) => {
  const multiplier = PageSizes['A4'][0] / 210
  const marginValue = marginValuesNumber[margin]
  marginValues[marginIndexPagePosition[pageNumberPosition[0]]] = marginValue * multiplier

  const { x, y, width, height } = page.getMediaBox()

  const size = [width, height]
  const newSize = [width, height + marginValues[1] + marginValues[3]]
  const sizeRatio = Math.round((size[0] / size[1]) * 100)
  const newSizeRatio = Math.round((newSize[0] / newSize[1]) * 100)

  page.setSize(width, height + marginValues[1] + marginValues[3])
  page.translateContent(x, y + marginValues[3])

  const pageFooter = `${COMPANY[env.basicAuthentication.fromCompany]} Page ${value} of ${totalPageCount}`
  const { text, textPositionX, textPositionY } = getTextPosition(
    pageFooter,
    width,
    height,
    marginValues,
    textSize,
    pageNumberPosition,
    customFont
  )

  page.drawText(text, {
    x: textPositionX,
    y: textPositionY,
    size: textSize,
    font: customFont
  })

  if (Math.abs(sizeRatio - newSizeRatio) > 1) {
    // Change page size
    page.setSize(size[0], size[1])
    const scale_content = Math.min(size[0] / newSize[0], size[1] / newSize[1])

    // Scale content
    page.scaleContent(scale_content, scale_content)
    const scaledDiffernece = {
      width: Math.round(size[0] - scale_content * newSize[0]),
      height: Math.round(size[1] - scale_content * newSize[1])
    }

    page.translateContent(Math.round(scaledDiffernece.width / 2), Math.round(scaledDiffernece.height / 2))
  } else {
    page.scale(size[0] / newSize[0], size[1] / newSize[1])
  }
}

export const addPageNumbers = async (pdfDoc, textSize = 10) => {
  const pageNumberPosition = 'b-c'
  const margin = 'Small'
  const startFromPage = 1
  const endPage = pdfDoc.getPageCount()
  const startingNumber = 1
  const marginValues = [0, 0, 0, 0]

  const fontBytes = await fetch(url).then(res => res.arrayBuffer())
  pdfDoc.registerFontkit(fontkit)
  const customFont = await pdfDoc.embedFont(fontBytes)

  const pages = pdfDoc.getPages()

  let number = startingNumber
  pages.slice(startFromPage - 1, endPage).forEach(page => {
    drawTextOnPage(page, endPage, number, textSize, customFont, pageNumberPosition, margin, marginValues)
    number++
  })

  return pdfDoc
}

export const generatePdfFromHtml = async (htmlRef, pdfName, setFile) => {
  try {
    const content = htmlRef.current
    const allBreaklineElements = content.querySelectorAll('.breakline')

    for (let i = 0; i < allBreaklineElements.length; i++) {
      let newContent = document.createElement('br')
      newContent.style['page-break-before'] = 'always'
      allBreaklineElements[i].replaceWith(newContent)
    }

    if (content) {
      const pdfOptions = {
        margin: 5,
        filename: pdfName,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          onclone: element => {
            const svgElements = Array.from(element.querySelectorAll('svg'))
            svgElements.forEach(s => {
              const bBox = s.getBBox()
              s.setAttribute('x', bBox.x)
              s.setAttribute('y', bBox.y)
              s.setAttribute('width', s.scrollWidth)
              s.setAttribute('height', s.scrollHeight)
            })
          }
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }

      html2pdf()
        .set(pdfOptions)
        .from(content)
        .toPdf()
        .output('blob')
        .then(pdf => {
          const pdfFile = new File([pdf], pdfName, { type: 'application/pdf' })
          setFile(pdfFile)
        })
    }
  } catch (e) {
    console.log(e)
  }
}
