import {
  acquireAccessToken,
  MenuAppBar,
  parseJwt,
  HasAccess,
  useWtxLocalization,
  NotFoundPage
} from '@wavetronix/common-components'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { env } from '../index.js'
import { useMsal } from '@azure/msal-react'
import { useEffect, useState, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import DocumentsPage from './DocumentsPage'
import ProductViewerPage from './pages/ProductViewerPage'
import OpportunitiesManagementPage from './pages/OpportunitiesManagementPage'
import SubmittalsManagementPage from './pages/SubmittalsManagementPage'
import PackageManagementPage from './pages/PackageManagementPage'
import BundleWrapperModal from './modals/BundleWrapperModal'
import CategoriesApi from '../api/CategoriesApi'
import version from '../env/version.json'
import CrmProductsApi from '../api/CrmProductsApi'
import DocumentsApi from '../api/DocumentsApi'
import { DOCUMENTTYPE_OPTIONS } from './controls/SelectOpportunityDocuments'

const drawerWidth = 300

const classes = {
  paper: {
    width: drawerWidth
  },
  dataGrid: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  },
  filterTitle: {
    fontFamily: 'Klavika',
    fontSize: 22
  },
  title: {
    fontFamily: 'Klavika',
    fontSize: 26
  },
  inlineTitle: {
    fontFamily: 'Klavika',
    fontSize: '26px',
    display: 'inline'
  },
  pageHeader: {
    marginLeft: '24px',
    marginTop: '30px',
    fontSize: '24px',
    flex: 1,
    display: 'inline',
    justifyContent: 'left'
  },
  tableRow: {
    cursor: 'pointer'
  }
}

export const URL_MAP = {
  documents: 0,
  search: 1,
  packages: 2,
  products: 3,
  opportunities: 4,
  '': 0
}

function MainPage() {
  const { instance, accounts } = useMsal()
  const localizedStrings = useWtxLocalization()
  const [uploadToken, setUploadToken] = useState('')
  const [state, setState] = useState({
    current: window.location.pathname.substring(1)
  })
  const [productState, setProductState] = useState({
    current: ''
  })

  document.getElementById('title').innerHTML = localizedStrings.title

  const { data: productTypes } = useQuery({
    queryKey: ['crmProducts'],
    queryFn: async () => {
      let categories = await CrmProductsApi.getCrmProducts(instance, accounts)
      return categories
    }
  })

  const { data: documents } = useQuery({
    queryKey: ['mydocuments'],
    queryFn: async () => {
      let result = await DocumentsApi.getMyDocuments(instance, accounts).then(res =>
        res.sort((a, b) => {
          if (a.order > b.order) {
            return 1
          } else {
            return -1
          }
        })
      )
      return result
    }
  })

  const { data: categories } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => await CategoriesApi.getCategories(instance, accounts)
  })

  const categoriesMap = useMemo(() => {
    if (categories) {
      return categories.reduce((map, obj) => {
        map[obj.id] = obj
        return map
      }, {})
    }
  }, [categories])

  useEffect(() => {
    async function resetUploadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setUploadToken(token)
    }
    if (!uploadToken || parseJwt(uploadToken).exp < Date.now() / 1000) {
      resetUploadToken()
    }
  }, [instance, accounts, uploadToken])

  const productDocMap = useMemo(() => {
    let pMap = {}

    if (productTypes && documents && categoriesMap) {
      for (let product of productTypes) {
        pMap[product.id] = { productInfo: product, documents: {}, productNumbers: product.productItemNumbers }
      }

      for (let doc of documents) {
        for (let docProdId of doc.crmProducts) {
          if (
            pMap[docProdId] &&
            !pMap[docProdId].documents[doc.id] &&
            categoriesMap[doc.documentType] &&
            DOCUMENTTYPE_OPTIONS.includes(categoriesMap[doc.documentType].name)
          ) {
            pMap[docProdId].documents[doc.id] = doc
          }
        }
      }
    }

    return pMap
  }, [productTypes, documents, categoriesMap])

  return (
    <div>
      <MenuAppBar env={env} fromCompany={env.basicAuthentication.fromCompany} />
      <div className='content'>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Navigate to='/documents' />} />
            <Route render={() => <Navigate to='/documents' />} />
            <Route
              path='/documents'
              element={
                <DocumentsPage
                  styles={classes}
                  tabState={state}
                  changeTabState={s => {
                    setState(s)
                  }}
                  showFullList={false}
                  productState={productState}
                  setProductState={setProductState}
                />
              }
            />
            <Route
              path='/search'
              element={
                <DocumentsPage
                  styles={classes}
                  tabState={state}
                  changeTabState={s => {
                    setState(s)
                  }}
                  showFullList={true}
                  productState={productState}
                  setProductState={setProductState}
                />
              }
            />
            <Route path='/byproduct' element={<ProductViewerPage styles={classes} />} />
            <Route path='/version' element={<div>{version.version}</div>} />
            <Route
              path='/products'
              element={
                <HasAccess env={env} allowedRoles={['Sales Admin', 'Sales Team Member', 'Dealer', 'Packages Admin']}>
                  <SubmittalsManagementPage
                    styles={classes}
                    tabState={state}
                    changeTabState={s => {
                      setState(s)
                    }}
                    showFullList={true}
                    productDocMap={productDocMap}
                  />
                </HasAccess>
              }
            />
            <Route
              path='/packages'
              element={
                <HasAccess env={env} allowedRoles={['Sales Admin', 'Sales Team Member', 'Dealer', 'Packages Admin']}>
                  <PackageManagementPage
                    styles={classes}
                    tabState={state}
                    changeTabState={s => {
                      setState(s)
                    }}
                    showFullList={true}
                    productDocMap={productDocMap}
                  />
                </HasAccess>
              }
            />
            <Route
              path='/opportunities'
              element={
                <HasAccess env={env} allowedRoles={['Sales Admin', 'Sales Team Member', 'Packages Admin']}>
                  <OpportunitiesManagementPage
                    styles={classes}
                    tabState={state}
                    changeTabState={s => {
                      setState(s)
                    }}
                    showFullList={true}
                    productDocMap={productDocMap}
                  />
                </HasAccess>
              }
            />
            <Route
              path='/opportunitypackage'
              element={
                <HasAccess env={env} allowedRoles={['Sales Admin', 'Sales Team Member', 'Packages Admin']}>
                  <BundleWrapperModal type={'opportunity'} productDocMap={productDocMap} categoriesMap={categoriesMap} />
                </HasAccess>
              }
            />
            <Route
              path='/manualpackage'
              element={
                <HasAccess env={env} allowedRoles={['Sales Admin', 'Sales Team Member', 'Packages Admin']}>
                  <BundleWrapperModal type={'package'} productDocMap={productDocMap} categoriesMap={categoriesMap} />
                </HasAccess>
              }
            />
            {/* This wildcard route needs to stay at the bottom */}
            <Route path='/*' element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  )
}
export default MainPage
